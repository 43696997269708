/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.btn-menu').on('click', function() {
          var $button = $(this);
          var $nav = $('.nav-primary');

          $button.toggleClass('active');
          $nav.toggleClass('active');
          $('body').toggleClass('modal-open');
        });

        var formatDisplay = function(quantity) {
          if (parseInt(quantity) === 0) {
            return 'Remove';
          }

          var first_half = '';
          var second_half = '';
          var whole = Math.floor(quantity/2);
          var decimal = (quantity/2) - whole;

          if (whole > 0) {
            first_half = whole + ' ';
          }

          if (decimal > 0) {
            second_half = '1/2 ';
          }

          return first_half + second_half + 'Dozen';
        };

        var displayHandlers = function() {

          $('.display-input').each(function() {
            inputDiv = $(this);
            var displayInput = inputDiv.find('input');
            var realInput = $(this).parent().find('input.qty');
            var realInputVal = parseInt(realInput.val());
            var realInputMin = parseInt(realInput.attr('min'));

            if (inputDiv.hasClass('cookies')) {
              displayInput.val(formatDisplay(realInputVal));
            } else {
              displayInput.val(realInputVal);
            }

            if (realInputVal > realInputMin) {
              $(this).find('.quantity-button.down').removeClass('disabled');
            }
          });

          // quantity button handler
          $('.quantity-button').on('click', function(e) {
            e.preventDefault();

            var button = $(this);
            var realInput = button.closest('.display-input').parent().find('input.qty');
            var origRealInputVal = parseInt(realInput.val());
            var realInputVal = parseInt(realInput.val());
            var realInputMin = parseInt(realInput.attr('min') ? realInput.attr('min') : 1 );
            var realInputMax = parseInt(realInput.attr('max') ? realInput.attr('max') : 100 );
            var realInputStep = parseInt(realInput.attr('step') ? realInput.attr('step') : 1 );

            var cartDisplayInput = button.parent().siblings('input');

            if ( !(button.hasClass('disabled')) ) {
              if (button.hasClass('up') && (realInputVal + realInputStep) <= realInputMax) {
                realInputVal += realInputStep;
              } else if (button.hasClass('down') && (realInputVal - realInputStep) >= realInputMin) {
                realInputVal -= realInputStep;
              }
            }

            // DEBUG IT
            // console.log('origRealInputVal: ' + origRealInputVal);
            // console.log('realInputVal: ' + realInputVal);
            // console.log('realInputMin: ' + realInputMin);
            // console.log('realInputMax: ' + realInputMax);
            // console.log('realInputStep: ' + realInputStep);

            // Only do something if the value has changed
            if (realInputVal !== origRealInputVal) {
              // Enable/disable up down buttons at mix/max
              if (realInputVal === realInputMax) {
                // Disable Down / Enable Up
                button.parent().children('.quantity-button.up').addClass('disabled');
                button.parent().children('.quantity-button.down').removeClass('disabled');
              } else if (realInputVal === realInputMin) {
                // Disable Up / Enable Down
                button.parent().children('.quantity-button.down').addClass('disabled');
                button.parent().children('.quantity-button.up').removeClass('disabled');
              } else {
                button.parent().children('.quantity-button.up').removeClass('disabled');
                button.parent().children('.quantity-button.down').removeClass('disabled');
              }

              // Update what pre-populated quantity value/price we're showing
              var quantities = button.parent().siblings('.display-quantity');
              quantities.addClass('d-none');
              quantities.find("[data-quantity='" + realInputVal + "']").parent().removeClass('d-none');

              // Update the value and trigger a change event so add to cart quantity is updated
              realInput.val(realInputVal);
              realInput.change();

              // Cart input update
              if (cartDisplayInput.parent().hasClass('cookies')) {
                cartDisplayInput.val(formatDisplay(realInputVal));
              } else {
                if (realInputVal > 0) {
                  cartDisplayInput.val(realInput.val());
                } else {
                  cartDisplayInput.val('');
                }
              }
            }

          });

        };

        displayHandlers();

        // ajax add to cart removes jquery selectors, so re-add them
        $('body').bind('updated_cart_totals', function() {
          displayHandlers();
        });

      },
      finalize: function() {
      }
    },
    'checkout': {
      init: function() {
        $( 'form.checkout' ).on('change', 'input.cg_tip', function() {
          $( document.body ).trigger( 'update_checkout' );
        });
      },
      finalize: function() {
      }
    },
    'home': {
      init: function() {
        var autocomplete;

        // *slaps roof of modal* this baby can hold so many messages
        var modal = $('#message-modal');
        var backBtn = modal.find('button.back');

        // Get all cookies
        var allBrowserCookies = Cookies.get();

        // If there isn't a woocommerce session cookie set, show the modal
        if (!Object.keys(allBrowserCookies).some(function(k){ return k.indexOf('wp_woocommerce_session') > -1; })) {
          $('.message-modal').addClass('d-none');
          $('.delivery-intro').removeClass('d-none');
          modal.modal('show');
        }

        var cgGetAddress = function() {
          var address = {};

          if (typeof autocomplete !== 'undefined') {
            var place = autocomplete.getPlace();

            var componentForm = {
              street_number: 'short_name',
              route: 'long_name',
              locality: 'long_name',
              administrative_area_level_1: 'short_name',
              country: 'short_name', // Short Name, WC wants the country code
              postal_code: 'short_name',
              postal_code_prefix: 'short_name',
            };

            if (typeof place !== 'undefined' && typeof place.address_components !== 'undefined') {
              // console.log(place.address_components);
              for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                  var val = place.address_components[i][componentForm[addressType]];
                  address[addressType] = val;
                }
              }
            }
          }

          return address;
        };

        $('.delivery-intro button.button').click(function() {
          var button = $(this);
          var shipping_type = button.data('type');

          $('.delivery-intro').addClass('d-none');
          $('.delivery-' + shipping_type).removeClass('d-none');

          backBtn.removeClass('d-none');
          backBtn.on('click', function() {
            $(this).addClass('d-none');
            $('.delivery-' + shipping_type).addClass('d-none');
            $('.delivery-intro').removeClass('d-none');
            $('.delivery-delivery-failed').addClass('d-none');
          });

          if (shipping_type === 'delivery') {
            var defaultBounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(48.320166, -123.794529),
              new google.maps.LatLng(48.709261, -123.251051)
            );
            var autocompleteInput = $('input.delivery-address')[0];

            autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
              // Optional bounds restrictions to Greater Victoria
              // bounds: defaultBounds,
              // strictBounds: true,
              types: ['address'],
              componentRestrictions: {country: 'ca'},
            });
            autocomplete.setFields(['address_components']); // Don't need anything else, save on API stuff
          }

        });

        $('.cg-shipping-btn').click(function() {
          var button = $(this);

          button.addClass('loading');

          var shipping_type = button.data('type');
          var pickup_location = button.data('pickup-location');
          var delivery_address = cgGetAddress();

          if (shipping_type === 'delivery' && $.isEmptyObject(delivery_address)) {
            $('.delivery-input p.error').removeClass('d-none');
          } else {
            $('.delivery-input p.error').addClass('d-none');
          }

          if (shipping_type) {
            $.ajax({
              // url: cookieguy_params.ajax_url,
              url: woocommerce_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'cg_set_shipping' ),
              method: 'POST',
              data: {
                action: 'cg_set_shipping',
                shipping_type: shipping_type,
                pickup_location: pickup_location,
                delivery_address: delivery_address,
              },
              success: function(response) {
                button.removeClass('loading');
                if (response.data.shipping_method) {
                  $('.shipping-option').html(response.data.shipping_method + ' &nbsp;•');
                }

                if ( !(response) || !(response.success)) {
                  $('.delivery-' + shipping_type).addClass('d-none');
                  $('.delivery-' + shipping_type + '-failed').removeClass('d-none');
                } else if (response.data.shipping_type !== shipping_type) {
                  $('.delivery-' + shipping_type).addClass('d-none');

                  if (response.data.incomplete_address) {
                    $('.delivery-' + shipping_type + '-unsure').removeClass('d-none');
                  } else {
                    $('.delivery-' + shipping_type + '-failed').removeClass('d-none');
                  }
                } else {
                  if (shipping_type === 'delivery') {
                    backBtn.addClass('d-none');
                    $('.delivery-delivery').addClass('d-none');
                    $('.delivery-delivery-success').removeClass('d-none');
                  } else {
                    // Hide the modal if it's all good
                    modal.modal('hide');
                  }
                }
              }
            });
          }

        });

        // JavaScript for quantity input stuff on shop page
        $('.post-type-archive-product').on('click', '.quantity input', function() {
          return false;
        });

        $('.post-type-archive-product').on('change input', '.quantity .qty', function() {
          var add_to_cart_button = $(this).parents('.product').find('.add_to_cart_button');
          // For AJAX add-to-cart actions
          add_to_cart_button.data('quantity', $(this).val());
          // For non-AJAX add-to-cart actions
          add_to_cart_button.attr( 'href', '?add-to-cart=' + add_to_cart_button.attr( 'data-product_id' ) + '&quantity=' + $( this ).val() );
        });

        //prevent category links from working
        $('.pbc_title a').on('click', function() {
          return false;
        });

        // populate combo modals
        var comboModal = $('#combo-modal');
        $('.product_type_composite.add_to_cart_button').on('click', function(e) {
          var button = $(this);
          e.preventDefault();
          e.stopPropagation();
          button.addClass('loading');

          $.ajax({
            // url: cookieguy_params.ajax_url,
            url: woocommerce_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'cg_populate_modal' ),
            method: 'POST',
            data: {
              action: 'cg_populate_modal',
              product_id: $(this).data('product_id')
            },
            success: function(response) {
              button.removeClass('loading');
              if ( !(response) || !(response.success) ) {
                window.location = response.data.redirect;
                return;
              } else {
                // Attach product content to modal body
                var modalBody = comboModal.find('.modal-body');
                modalBody.html(response.data.modal);

                // Initialize compsoite product form on new content
                modalBody.find('.composite_form .composite_data').wc_composite_form();

                // And finally show the modal
                comboModal.modal('show');
              }
            }
          });
        });

      }
    },
    'cart': {
      init: function() {

      //Adjusting Woocommerce table
      $('.component_table_item td.product-name').attr('colspan',4);

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
